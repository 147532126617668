import { runWithAdal } from "react-adal"
import { authContext } from "./Services/AzureAdAuth"
import './Content/CSS/index.css';

runWithAdal(
    authContext,
    () => { require('./indexApp') },
    true
)

